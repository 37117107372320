import { Tree, TreeNode } from 'react-organizational-chart';
import {
  ActionIcon,
  Avatar,
  Center,
  createStyles,
  Group,
  Indicator,
  Menu,
  Paper,
  Text,
  Tooltip,
} from '@mantine/core';
import {
  ChevronUp,
  Circle,
  CircleOff,
  Dots,
  Pencil,
  Plus,
  Trash,
} from 'tabler-icons-react';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import ISection from '../../models/ISection';
import { SectionCreateDialog } from './SectionCreateDialog';
import { SectionEditDialog } from './SectionEditDialog';
import { SectionDetailDialog } from './SectionDetailDialog';
import SectionService from '../../services/SectionService';
import { showAppNotifcation } from '../../utility/NotificationConfigs';
import IUser from '../../models/IUser';
import { ROLE_ENUM, ROLE_ENUM_VALUES } from '../../api_enums/ROLE_ENUM';
import { SectionContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import { RoleElement } from '../entityelements/RoleElement';
import { useTranslation } from 'react-i18next';
import { AuthContext, AuthContextType } from '../../context/AuthProvider';

const useStyle = createStyles((theme) => ({
  active: {
    display: 'inline-block',
  },
  disable: {
    display: 'inline-block',
    backgroundColor: 'rgba(0,0,0,0.04)',
  },
}));

interface ISectionNode {
  childCount: number;
  section: ISection;
  childs: ISectionNode[];
}

function ShowIndicator(props: { children: ReactNode }) {
  const { userInfo } = useContext(AuthContext) as AuthContextType;
  return userInfo?.section_roles.includes(ROLE_ENUM.MANAGER, 0) ? (
    <>{props.children}</>
  ) : (
    <></>
  );
}

function ShowMenu(props: { children: ReactNode; sectionId: number }) {
  const { userInfo } = useContext(AuthContext) as AuthContextType;
  return userInfo?.section_roles.includes(ROLE_ENUM.MANAGER, 0) ||
    props.sectionId === userInfo?.section_id ? (
    <>{props.children}</>
  ) : (
    <></>
  );
}

function CheckEditPermission(sectionId: number) {
  const { userInfo } = useContext(AuthContext) as AuthContextType;
  return (
    userInfo?.section_roles.includes(ROLE_ENUM.MANAGER, 0) ||
    sectionId === userInfo?.section_id
  );
}

function CheckAdminPermission() {
  const { userInfo } = useContext(AuthContext) as AuthContextType;
  return userInfo?.section_roles.includes(ROLE_ENUM.MANAGER, 0);
}

function RolesDisplay(props: { roles: string[]; is_active: boolean }) {
  return (
    <>
      {props.roles.length === 1 && (
        <Tooltip label={<RoleElement value={props.roles[0]} />}>
          <Avatar
            color={
              props.is_active
                ? ROLE_ENUM_VALUES.get(props.roles[0])?.color
                : 'gray'
            }
            radius="xl"
          >
            {ROLE_ENUM_VALUES.get(props.roles[0])?.icon}
          </Avatar>
        </Tooltip>
      )}
      {props.roles.length > 1 && (
        <Tooltip.Group openDelay={100}>
          <Avatar.Group spacing="sm">
            {props.roles.slice(0, 2).map((r: string) => (
              <Tooltip key={r} label={<RoleElement value={r} />} withArrow>
                <Avatar
                  color={
                    props.is_active ? ROLE_ENUM_VALUES.get(r)?.color : 'gray'
                  }
                  radius="xl"
                >
                  {ROLE_ENUM_VALUES.get(r)?.icon}
                </Avatar>
              </Tooltip>
            ))}
            {props.roles.length > 2 && (
              <Tooltip
                withArrow
                position="bottom"
                label={
                  <>
                    {' '}
                    {props.roles.slice(2).map((r) => (
                      <div key={r}>
                        <RoleElement value={r} />
                      </div>
                    ))}{' '}
                  </>
                }
              >
                <Avatar radius="xl">+{props.roles.length - 2}</Avatar>
              </Tooltip>
            )}
          </Avatar.Group>
        </Tooltip.Group>
      )}
    </>
  );
}

function NodeLabel(props: {
  node: ISectionNode;
  expanded: boolean;
  setExpanded: Function;
  handleMenuOption: Function;
  handleDetailDialog: Function;
}) {
  const { t } = useTranslation();
  const { userInfo } = useContext(AuthContext) as AuthContextType;
  const { classes } = useStyle();

  return (
    <Paper
      shadow="sm"
      radius="lg"
      p="md"
      className={
        props.node.section.is_active ? classes.active : classes.disable
      }
    >
      <Group>
        {props.expanded && (
          <RolesDisplay
            roles={props.node.section.roles}
            is_active={props.node.section.is_active}
          />
        )}
        {!props.expanded && (
          <Indicator
            inline
            size={14}
            offset={3}
            position="bottom-end"
            color="red"
            label={props.node.childCount}
            withBorder
          >
            <RolesDisplay
              roles={props.node.section.roles}
              is_active={props.node.section.is_active}
            />
          </Indicator>
        )}
        <Text
          weight={700}
          size={'xl'}
          color={props.node.section.is_active ? '' : 'dimmed'}
          style={{ cursor: 'pointer' }}
          onClick={() => props.handleDetailDialog(props.node.section)}
        >
          {props.node.section.name}
        </Text>
        <Text size={'sm'} color={props.node.section.is_active ? '' : 'dimmed'}>
          {props.node.section.description}
        </Text>
        <ShowMenu sectionId={props.node.section.id}>
          <Menu>
            <Menu.Target>
              <ActionIcon>
                <Dots size={16} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                color="green"
                icon={<Plus size={14} />}
                disabled={!CheckAdminPermission()}
                onClick={() =>
                  props.handleMenuOption(
                    MenuAction.CREATE,
                    props.node.section.id,
                    null,
                  )
                }
              >
                {t('SectionManagement.AddSection')}
              </Menu.Item>
              <Menu.Item
                color="orange"
                icon={<Pencil size={14} />}
                disabled={!CheckEditPermission(props.node.section.id)}
                onClick={() =>
                  props.handleMenuOption(
                    MenuAction.EDIT,
                    null,
                    props.node.section,
                  )
                }
              >
                {t('main.Edit')}
              </Menu.Item>
              {props.node.section.is_active && (
                <Menu.Item
                  color="yellow"
                  icon={<CircleOff size={14} />}
                  disabled={!CheckAdminPermission()}
                  onClick={() =>
                    props.handleMenuOption(
                      MenuAction.TOGGLE_ACTIVE,
                      null,
                      props.node.section,
                    )
                  }
                >
                  {t('main.Disable')}
                </Menu.Item>
              )}
              {!props.node.section.is_active && (
                <Menu.Item
                  color="lime"
                  icon={<Circle size={14} />}
                  disabled={!CheckAdminPermission()}
                  onClick={() =>
                    props.handleMenuOption(
                      MenuAction.TOGGLE_ACTIVE,
                      null,
                      props.node.section,
                    )
                  }
                >
                  {t('main.Active')}
                </Menu.Item>
              )}
              <Menu.Item
                color="red"
                icon={<Trash size={14} />}
                disabled={!CheckAdminPermission()}
                onClick={() =>
                  props.handleMenuOption(
                    MenuAction.DELETE,
                    null,
                    props.node.section,
                  )
                }
              >
                {t('main.Delete')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </ShowMenu>
      </Group>
      {props.node.section.led_by && (
        <Text color="dimmed" size="md">
          {(props.node.section.led_by as IUser)?.name}
        </Text>
      )}
      {props.node.childCount > 0 && (
        <Center>
          <ActionIcon
            color="dark"
            size="sm"
            radius="xl"
            onClick={() => props.setExpanded(!props.expanded)}
            style={props.expanded ? { transform: 'rotate(180deg)' } : {}}
          >
            <ChevronUp />
          </ActionIcon>
        </Center>
      )}
    </Paper>
  );
}

function ExpandedNode(props: {
  node: ISectionNode;
  expanded: boolean;
  setExpanded: Function;
  handleMenuOption: Function;
  handleDetailDialog: Function;
}) {
  return (
    <TreeNode
      label={
        <NodeLabel
          node={props.node}
          expanded={props.expanded}
          setExpanded={props.setExpanded}
          handleMenuOption={props.handleMenuOption}
          handleDetailDialog={props.handleDetailDialog}
        />
      }
    >
      {props.node.childs.map((c) => (
        <Node
          key={c.section.id}
          node={c}
          handleMenuOption={props.handleMenuOption}
          handleDetailDialog={props.handleDetailDialog}
        />
      ))}
    </TreeNode>
  );
}

function CollapsedNode(props: {
  node: ISectionNode;
  expanded: boolean;
  setExpanded: Function;
  handleMenuOption: Function;
  handleDetailDialog: Function;
}) {
  return (
    <TreeNode
      label={
        <NodeLabel
          node={props.node}
          expanded={props.expanded}
          setExpanded={props.setExpanded}
          handleMenuOption={props.handleMenuOption}
          handleDetailDialog={props.handleDetailDialog}
        />
      }
    />
  );
}

function Node(props: {
  node: ISectionNode;
  handleMenuOption: Function;
  handleDetailDialog: Function;
}) {
  const [expanded, setExpanded] = useState(true);

  return expanded ? (
    <ExpandedNode
      node={props.node}
      expanded={expanded}
      setExpanded={setExpanded}
      handleMenuOption={props.handleMenuOption}
      handleDetailDialog={props.handleDetailDialog}
    />
  ) : (
    <CollapsedNode
      node={props.node}
      expanded={expanded}
      setExpanded={setExpanded}
      handleMenuOption={props.handleMenuOption}
      handleDetailDialog={props.handleDetailDialog}
    />
  );
}

function transformSectionsToTree(
  rootNode: ISection,
  sections: ISection[],
): ISectionNode {
  let children = sections.filter(
    (e: ISection) => (e.supervised_by as ISection)?.id == rootNode.id,
  );
  return {
    section: rootNode,
    childCount: children.length,
    childs: children.map((child) => transformSectionsToTree(child, sections)),
  };
}

enum MenuAction {
  'CREATE',
  'EDIT',
  'DELETE',
  'TOGGLE_ACTIVE',
}

// TODO: check restrictions + errors -> has to be always one admin section? orderer cannot be a root ...
export function SectionManagement() {
  const { t } = useTranslation();
  const ctx = useContext(SectionContext) as AbstractContextType<ISection>;
  const [rootNodes, setRootNodes] = useState<ISectionNode[]>([]);
  const [openedCreate, setOpenedCreate] = useState(false);
  const [supervisorValue, setSupervisorValue] = useState<number | null>(null);
  const [openedEdit, setOpenedEdit] = useState(false);
  const [sectionEdit, setSectionEdit] = useState<ISection | null>(null);
  const [openedDetail, setOpenedDetail] = useState(false);
  const [sectionDetail, setSectionDetail] = useState<ISection | null>(null);

  const handleMenuOption = (
    action: MenuAction,
    supervisor?: number,
    section?: ISection,
  ) => {
    if (action == MenuAction.CREATE) {
      setSupervisorValue(supervisor || null);
      setOpenedCreate(true);
    } else if (action == MenuAction.EDIT) {
      setSectionEdit(section || null);
      setOpenedEdit(true);
    } else if (action == MenuAction.DELETE && section) {
      SectionService.remove(section.id)
        .then(() => {
          ctx.remove(section.id);
          showAppNotifcation(true, t('SectionManagement.SectionDeleted'));
        })
        .catch(() =>
          showAppNotifcation(
            false,
            t('SectionManagement.SectionCouldNotBeDeleted'),
          ),
        );
    } else if (action == MenuAction.TOGGLE_ACTIVE && section) {
      const initial = section.is_active;
      SectionService.patch(section.id, { is_active: !initial })
        .then(() => {
          showAppNotifcation(
            true,
            t('SectionManagement.SectionActivated', {
              initial: initial ? 'de' : '',
            }),
          );
        })
        .catch(() =>
          showAppNotifcation(
            false,
            t('SectionManagement.SectionCouldNotActivated', {
              initial: initial ? 'de' : '',
            }),
          ),
        );
    }
  };

  const handleDetailDialog = (section: ISection) => {
    setSectionDetail(section || null);
    setOpenedDetail(true);
  };

  useEffect(() => {
    if (!ctx.loading) {
      setRootNodes(
        ctx.entities
          .filter((e) => e.supervised_by == null)
          .map((rn) => transformSectionsToTree(rn, ctx.entities)),
      );
    }
  }, [ctx.entities, ctx.loading]);

  return (
    <>
      <SectionCreateDialog
        opened={openedCreate}
        setOpened={setOpenedCreate}
        supervisor={supervisorValue}
      />
      <SectionEditDialog
        opened={openedEdit}
        setOpened={setOpenedEdit}
        section={sectionEdit || ({} as ISection)}
      />
      <SectionDetailDialog
        opened={openedDetail}
        setOpened={setOpenedDetail}
        section={sectionDetail || ({} as ISection)}
      />
      <Tree
        label={
          <ShowIndicator>
            <Paper style={{ display: 'inline-block' }}>
              <ActionIcon
                color="green"
                size="lg"
                onClick={() => setOpenedCreate(true)}
              >
                <Plus size={20} />
              </ActionIcon>
            </Paper>
          </ShowIndicator>
        }
        lineWidth={'2px'}
        lineColor={'#bbc'}
        lineBorderRadius={'12px'}
      >
        {rootNodes.length > 0 &&
          rootNodes.map((rn) => (
            <Node
              key={rn.section.id}
              handleMenuOption={handleMenuOption}
              node={rn}
              handleDetailDialog={handleDetailDialog}
            />
          ))}
        {rootNodes.length == 0 && !ctx.loading && (
          <Text>{t('SectionManagement.NoAvailableSection')}</Text>
        )}
      </Tree>
    </>
  );
}
