import {
  ActionIcon,
  createStyles,
  Group,
  Paper,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
} from '@mantine/core';

import { PackageUnitEditDialog } from './PackageUnitEditDialog';
import { PackageUnitDeleteDialog } from './PackageUnitDeleteDialog';
import { PackageUnitCreateDialog } from './PackageUnitCreateDialog';
import React, { useContext, useState } from 'react';
import IPackageUnit from '../../models/IPackageUnit';
import { PackageUnitContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import { useTranslation } from 'react-i18next';
import cx from 'clsx';
import { Square, SquareCheck, X } from 'tabler-icons-react';
import { searchPackageUnit } from '../../utility/search';

const useStyles = createStyles((theme) => ({
  header: {
    zIndex: 1,
    position: 'sticky',
    top: 0,
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    transition: 'box-shadow 150ms ease',
    after: {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: 'rem(1px) solid',
    },
  },
  scrolled: {
    boxShadow: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05)',
  },
}));

function createRows(elements: IPackageUnit[], searchValue: string) {
  return elements
    .filter((element) => {
      return searchPackageUnit(element, searchValue);
    })
    .map((element) => {
      return (
        <tr key={element.id}>
          <td> {element.name} </td>
          <td> {element.description} </td>
          <td>
            {element.is_active ? (
              <SquareCheck color={'grey'} />
            ) : (
              <Square color={'grey'} />
            )}
          </td>
          <td>
            <Group spacing="xs">
              <PackageUnitEditDialog packageUnit={element} />
              <PackageUnitDeleteDialog packageUnit={element} />
            </Group>
          </td>
        </tr>
      );
    });
}

function PackageUnitTable() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const ctx = useContext(
    PackageUnitContext,
  ) as AbstractContextType<IPackageUnit>;
  const [scrolled, setScrolled] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  return (
    <Paper withBorder radius="md" p="xs">
      <Stack>
        <Title order={2}>{t('PackageUnitTable.Title')}</Title>
        <Group>
          <PackageUnitCreateDialog />
        </Group>
        <TextInput
          placeholder={t('main.Search')}
          radius="md"
          size="md"
          rightSection={
            searchValue && (
              <ActionIcon onClick={() => setSearchValue('')}>
                {' '}
                <X />{' '}
              </ActionIcon>
            )
          }
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <ScrollArea
          h={'85vh'}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
          <Table striped verticalSpacing="xs" fontSize="md">
            <thead
              className={cx(classes.header, { [classes.scrolled]: scrolled })}
            >
              <tr>
                <th>{t('PackageUnitTable.Name')}</th>
                <th>{t('PackageUnitTable.Description')}</th>
                <th>{t('main.Active')}</th>
                <th>{t('main.Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {createRows(ctx.entities, searchValue)}
              {ctx.loading &&
                [1, 2].map((id) => {
                  return (
                    <tr key={id}>
                      <td>
                        <Skeleton height={12} mt={6} width="50%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="70%" radius="xl" />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </ScrollArea>
        {!ctx.loading && ctx.entities.length == 0 && (
          <Text align="center">
            {t('PackageUnitTable.NoPackagingUnitAvailable')}
          </Text>
        )}
      </Stack>
    </Paper>
  );
}

export default PackageUnitTable;
