import { Grid } from '@mantine/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import StatsControls from './metrics/StatsControls';
import { Notes, ShoppingCart, Truck, TruckDelivery } from 'tabler-icons-react';
import StatsVerticalBarChart from './metrics/StatsVerticalBarChart';
import { StatisticContext } from '../context/ContextWrapper';
import StatsControlsBarchart from './metrics/StatsControlsBarchart';
import { IResourceSection } from '../models/IStatistic';
import { AuthContext, AuthContextType } from '../context/AuthProvider';

function Home() {
  const { t } = useTranslation();
  const ctxStatistic = useContext(StatisticContext);
  const { getOptions, setOptions } = useContext(AuthContext) as AuthContextType;
  const options = getOptions();

  const dataStatsControls = [
    {
      icon: Notes,
      label: t('StatsControlsBarchart.Requirements'),
      value: ctxStatistic.entities[0]?.total_inquiry?.count || 0,
      description: t('StatisticsHelp.Requirements'),
      EditingDescription: '',
    },
    {
      icon: Truck,
      label: t('StatsControlsBarchart.AvailableVehicle'),
      value: ctxStatistic.entities[0]?.total_available_vehicle?.count || 0,
      description: t('StatisticsHelp.AvailableVehicle'),
      EditingDescription: '',
    },
    {
      icon: ShoppingCart,
      label: t('StatsControlsBarchart.OrderRequirements'),
      value: ctxStatistic.entities[0]?.total_ordered_inquiry?.count || 0,
      description: t('StatisticsHelp.OrderRequirements'),
      EditingDescription: '',
    },
    {
      icon: TruckDelivery,
      label: t('StatsControlsBarchart.SendOrders'),
      value: ctxStatistic.entities[0]?.total_delivered_inquiry?.count || 0,
      description: t('StatisticsHelp.SendOrders'),
      EditingDescription: '',
    },
  ];

  const dataResource = {
    labels: ctxStatistic.entities[0]?.resources.name_list,
    datasets: [
      {
        label: t('StatsControlsBarchart.OpenResources'),
        data: ctxStatistic.entities[0]?.resources.open_count_list,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgb(153, 102, 255)',
        borderWidth: 1,
      },
      {
        label: t('StatsControlsBarchart.DeliveredResources'),
        data: ctxStatistic.entities[0]?.resources.delivered_count_list,
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgb(255, 159, 64)',
        borderWidth: 1,
      },
    ],
  };

  const dataSection = {
    labels: ctxStatistic.entities[0]?.section.name_list,
    datasets: [
      {
        label: t('StatsControlsBarchart.Employee'),
        data: ctxStatistic.entities[0]?.section.employee_count_list,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const transformQueryResult = (queryResult: IResourceSection[]) => {
    const sections: string[] = [];
    const resourcesSet = new Set<string>();
    const sectionResourceMap: Record<string, Record<string, number>> = {};

    queryResult?.forEach((row) => {
      const sectionName = row.section_info?.name || 'Unknown Section';
      const resourceName = row.resource_info?.name || 'Unknown Resource';

      if (!sections.includes(sectionName)) sections.push(sectionName);
      resourcesSet.add(resourceName);

      if (!sectionResourceMap[sectionName]) {
        sectionResourceMap[sectionName] = {};
      }

      sectionResourceMap[sectionName][resourceName] = row.count || 0;
    });

    const resources = Array.from(resourcesSet);

    const datasetsResource = resources.map((resource, index) => ({
      label: resource,
      data: sections.map(
        (section) => sectionResourceMap[section]?.[resource] || 0,
      ),
      backgroundColor: `rgba(${(index * 50) % 255}, ${(index * 100) % 255}, ${(index * 150) % 255}, 0.5)`,
    }));

    const datasetsSection = sections.map((section, index) => ({
      label: section,
      data: resources.map(
        (resource) => sectionResourceMap[section]?.[resource] || 0,
      ),
      backgroundColor: `rgba(${(index * 50) % 255}, ${(index * 100) % 255}, ${(index * 150) % 255}, 0.5)`,
    }));

    return { sections, resources, datasetsResource, datasetsSection };
  };

  const resourceSectionData = transformQueryResult(
    ctxStatistic.entities[0]?.resource_section,
  );

  /*
   * X-Axis: Resources
   * Y-Axis: Sections
   * The plot is the stacked Bar chart
   */
  const dataSectionPerResource = {
    labels: resourceSectionData.resources,
    datasets: resourceSectionData.datasetsSection,
  };

  return (
    <>
      <Grid>
        <Grid.Col xs={12}>
          <StatsControls data={dataStatsControls} />
        </Grid.Col>
        <Grid.Col xs={12}>
          <StatsControlsBarchart />
        </Grid.Col>
        <Grid.Col xs={12}>
          <StatsVerticalBarChart
            decription={t('StatisticsHelp.RequestedResources')}
            EditingDescription={t('StatisticsHelp.RequestedResourcesEditing')}
            title={
              t('StatsControlsBarchart.RequestedResources') +
              ' (' +
              (options.indicator_timespan == 1
                ? t('StatsControlsBarchart.LastHour')
                : t('StatsControlsBarchart.TimeInterval', {
                    name: options.indicator_timespan.toString(),
                  })) +
              ')'
            }
            data={dataSectionPerResource}
            stacked={true}
          />
        </Grid.Col>
        <Grid.Col xs={12}>
          <StatsVerticalBarChart
            decription={t('StatisticsHelp.AllRequestedResources')}
            EditingDescription={''}
            title={t('StatsControlsBarchart.RequestedResources')}
            data={dataResource}
            stacked={true}
          />
        </Grid.Col>
        <Grid.Col xs={12}>
          <StatsVerticalBarChart
            decription={t('StatisticsHelp.EmployeeNumber')}
            EditingDescription={t('StatisticsHelp.EmployeeNumberEditing')}
            title={
              t('StatsControlsBarchart.Employee') +
              ': ' +
              ctxStatistic.entities[0]?.section.employee_count
            }
            data={dataSection}
            stacked={false}
          />
        </Grid.Col>
      </Grid>
    </>
  );
}

export default Home;
