import React, { useState } from 'react';
import { ActionIcon, Button, Group, Modal, Stack, Text } from '@mantine/core';
import { RefreshAlert } from 'tabler-icons-react';
import { showAppNotifcation } from '../../utility/NotificationConfigs';
import VehicleService from '../../services/VehicleService';
import { useTranslation } from 'react-i18next';
import IVehicle from '../../models/IVehicle';

export function VehicleRestoreDialog(props: { vehicle: IVehicle }) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('VehicleRestoreDialog.Title')}
      >
        <>
          <Stack>
            <Text>
              {t('VehicleRestoreDialog.StatusResetConfirm', {
                name: props.vehicle.radio_call_name,
              })}{' '}
            </Text>
            <Group>
              <Button
                color="yellow"
                onClick={() => {
                  VehicleService.resetStatus(props.vehicle.id)
                    .then((res: any) => {
                      showAppNotifcation(
                        true,
                        t('VehicleRestoreDialog.StatusResetSuccessfully'),
                      );
                    })
                    .catch(() => {
                      showAppNotifcation(
                        false,
                        t('VehicleRestoreDialog.CanNotResetStatus'),
                      );
                    })
                    .finally(() => setOpened(false));
                }}
              >
                {t('VehicleRestoreDialog.Reset')}
              </Button>
              <Button color="gray" onClick={() => setOpened(false)}>
                {t('main.Cancel')}
              </Button>
            </Group>
          </Stack>
        </>
      </Modal>

      <ActionIcon
        color="yellow"
        size="lg"
        onClick={() => setOpened(true)}
        title={t('VehicleRestoreDialog.Title')}
      >
        <RefreshAlert />
      </ActionIcon>
    </>
  );
}
