import IInquiry from '../../models/IInquiry';
import { Button, Container, Grid, Group, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface Props {
  inquiry: IInquiry;
  onFinish: Function;
}

export function InquiryInfo(props: Props) {
  const { t } = useTranslation();

  return (
    <div style={{ position: 'relative' }}>
      <Container my="md">
        <Grid>
          <Grid.Col xs={12}>
            <Text size={'xs'}>{t('InquiryForm.requester_info')}</Text>
            <Text>{props.inquiry?.requester_info}</Text>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Text size={'xs'}>{t('InquiryForm.recipient_info')}</Text>
            <Text>{props.inquiry?.recipient_info}</Text>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Text size={'xs'}>{t('InquiryForm.comment')}</Text>
            <Text>{props.inquiry?.comment}</Text>
          </Grid.Col>
          <Grid.Col xs={12}>
            <Group>
              <Button color="gray" onClick={() => props.onFinish()}>
                {t('main.Close')}
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </Container>
    </div>
  );
}
