import { Button, Container, Grid, Group, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

export function Help(props: {
  description: string;
  EditingDescription: string;
  onFinish: Function;
}) {
  const { t } = useTranslation();

  return (
    <div style={{ position: 'relative' }}>
      <Container my="md">
        <Grid>
          <Grid.Col xs={12}>
            <Text>{props.description}</Text>
          </Grid.Col>
          {props.EditingDescription && (
            <Grid.Col xs={12}>
              <Text size={'xl'}>{t('StatisticsHelp.Edit')}:</Text>
              <Text>{props.EditingDescription}</Text>
            </Grid.Col>
          )}
          <Grid.Col xs={12}>
            <Group>
              <Button color="gray" onClick={() => props.onFinish()}>
                {t('main.Close')}
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </Container>
    </div>
  );
}
