import {
  Anchor,
  Avatar,
  Button,
  Group,
  Header,
  Image,
  Paper,
  Popover,
  SimpleGrid,
  Text,
} from '@mantine/core';
import React, { useContext, useState } from 'react';
import { AuthContext, AuthContextType } from '../../context/AuthProvider';
import { useTranslation } from 'react-i18next';
import { ClipboardCheck } from 'tabler-icons-react';
import { ROLE_ENUM } from '../../api_enums/ROLE_ENUM';

export function AppHeader() {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const { hasRight } = useContext(AuthContext) as AuthContextType;
  const userdata = localStorage.getItem('userdata');
  const user = userdata ? JSON.parse(userdata) : null;
  const { getOptions, setOptions } = useContext(AuthContext) as AuthContextType;
  const options = getOptions();

  return (
    <Header height={60} p="xs">
      {
        /* Header content */
        <Paper>
          <SimpleGrid cols={3}>
            <div style={{ width: 100, height: 40 }}>
              <Anchor href="/">
                <Image
                  height={40}
                  fit="contain"
                  src={
                    options.dark_mode
                      ? '/Logo_Katma_white.svg'
                      : '/Logo_Katma.svg'
                  }
                  withPlaceholder
                />
              </Anchor>
            </div>
            <Button
              color="teal"
              variant="light"
              component="a"
              href="/accept_delivery"
              leftIcon={<ClipboardCheck />}
              disabled={!hasRight([ROLE_ENUM.RECIPIENT])}
            >
              {t('AcceptDeliveryDialog.DeliveryTitle')}
            </Button>
            <Group position="right">
              <Popover
                opened={opened}
                onClose={() => setOpened(false)}
                position="bottom"
                withArrow
              >
                {/*<Popover.Target>*/}
                <Group>
                  <Avatar
                    onClick={() => setOpened((o) => !o)}
                    radius="xl"
                  ></Avatar>
                  <div style={{ flex: 1, marginRight: 15 }}>
                    <Text size="sm" weight={500}>
                      {user?.user_name} ({user?.section_name})
                    </Text>
                  </div>
                </Group>
              </Popover>
            </Group>
          </SimpleGrid>
        </Paper>
      }
    </Header>
  );
}
