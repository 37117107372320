import { INQUIRY_STATUS_ENUM_VALUES } from '../api_enums/INQUIRY_STATUS_ENUM';
import { INQUIRY_PRIORITY_ENUM_VALUES } from '../api_enums/INQUIRY_PRIORITY_ENUM';
import IInquiry, { getPrefixedInquiryId } from '../models/IInquiry';
import moment from 'moment';
import IResource from '../models/IResource';
import ILocation from '../models/ILocation';
import IUser from '../models/IUser';
import ISection from '../models/ISection';
import IDelivery from '../models/IDelivery';
import { DELIVERY_STATUS_ENUM_VALUES } from '../api_enums/DELIVERY_STATUS_ENUM';
import IVehicle from '../models/IVehicle';
import IVehicleType from '../models/IVehicleType';
import IPackageUnit from '../models/IPackageUnit';
import IOrderPosition from '../models/IOrder';
import { ORDER_STATUS_ENUM_VALUES } from '../api_enums/ORDER_STATUS_ENUM';
import { RESOURCE_TYPE_ENUM_VALUES } from '../api_enums/RESOURCE_TYPE_ENUM';
import { VOLUME_UNIT_ENUM_VALUES } from '../api_enums/VOLUME_UNIT_ENUM';
import { VEHICLE_STATUS_ENUM_VALUES } from '../api_enums/VEHICLE_STATUS_ENUM';
import { RADIO_CALL_PREFIX_ENUM_VALUES } from '../api_enums/RADIO_CALL_PREFIX_ENUM';

export function searchInquiry(element: IInquiry, searchValue: string) {
  return (
    searchValue === '' ||
    getPrefixedInquiryId(element.id)
      .toLowerCase()
      .includes(searchValue.toLowerCase().trim()) ||
    moment
      .utc(element.created_at)
      .local()
      .format('DD.MM.YYYY - HH:mm:ss')
      .includes(searchValue.toLowerCase().trim()) ||
    INQUIRY_STATUS_ENUM_VALUES.get(element.status)
      .label.toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    INQUIRY_PRIORITY_ENUM_VALUES.get(element.priority)
      .label.toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element.amount
      ?.toString()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element.inquired_is as IResource)?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element.deliver_to as ILocation)?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element.inquired_by as IUser)?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element.is_waiting_at as ISection)?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element.ordered_from as ISection)?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element?.requester
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element?.recipient
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim())
  );
}

export function searchOrderedInquiry(element: IInquiry, searchValue: string) {
  return (
    searchValue === '' ||
    getPrefixedInquiryId(element.id)
      .toLowerCase()
      .includes(searchValue.toLowerCase().trim()) ||
    moment
      .utc(element.created_at)
      .local()
      .format('DD.MM.YYYY - HH:mm:ss')
      .includes(searchValue.toLowerCase().trim()) ||
    INQUIRY_STATUS_ENUM_VALUES.get(element.status)
      .label.toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    INQUIRY_PRIORITY_ENUM_VALUES.get(element.priority)
      .label.toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element.amount
      ?.toString()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element.inquired_is as IResource)?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element.deliver_to as ILocation)?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element.inquired_by as IUser)?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element.is_waiting_at as ISection)?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element.ordered_from as ISection)?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element?.requester
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element?.recipient
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element?.remain_loading
      ?.toString()
      .trim()
      .includes(searchValue.toLowerCase().trim())
  );
}

export function searchDelivery(element: IDelivery, searchValue: string) {
  return (
    searchValue === '' ||
    getPrefixedInquiryId(element.id)
      .toLowerCase()
      .includes(searchValue.toLowerCase().trim()) ||
    moment
      .utc(element.created_at)
      .local()
      .format('DD.MM.YYYY - HH:mm:ss')
      .includes(searchValue.toLowerCase().trim()) ||
    DELIVERY_STATUS_ENUM_VALUES.get(element.status)
      .label.toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    ((element.delivered_through as IVehicle)?.type_of as IVehicleType)?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element.delivered_through as IVehicle)?.radio_call_name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element.delivered_through as IVehicle)?.license_plate
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element.delivered_through as IVehicle)?.max_cargo
      ?.toString()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (
      (element.delivered_through as IVehicle)?.package_unit as IPackageUnit
    )?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (
      ((element.delivered_through as IVehicle)?.package_unit as IPackageUnit)
        ?.name +
      ',' +
      (element.delivered_through as IVehicle)?.max_weight
    )
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element.delivered_through as IVehicle)?.weight_unit
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element?.loaded_amount
      ?.toString()
      .trim()
      .includes(searchValue.toLowerCase().trim())
  );
}

export function searchOrderPosition(
  element: IOrderPosition,
  searchValue: string,
) {
  return (
    searchValue === '' ||
    getPrefixedInquiryId((element?.inquired_through as IInquiry)?.id)
      .toLowerCase()
      .includes(searchValue.toLowerCase().trim()) ||
    ORDER_STATUS_ENUM_VALUES.get(element.status)
      .label.toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    INQUIRY_PRIORITY_ENUM_VALUES.get(element.priority)
      .label.toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element?.remain_loading
      ?.toString()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    ((element.inquired_through as IInquiry)?.inquired_is as IResource)?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    ((element.inquired_through as IInquiry)?.deliver_to as ILocation)?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element?.loaded_amount
      ?.toString()
      .trim()
      .includes(searchValue.toLowerCase().trim())
  );
}

export function searchLocation(element: ILocation, searchValue: string) {
  return (
    searchValue === '' ||
    element?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element?.street_name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element.house_number
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element.postal_code
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element.city
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element.belongs_to as ISection)?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element?.description
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim())
  );
}

export function searchResource(element: IResource, searchValue: string) {
  return (
    searchValue === '' ||
    element?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    RESOURCE_TYPE_ENUM_VALUES.get(element?.type)
      ?.label.toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element?.package_unit as IPackageUnit)?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element?.weight
      ?.toString()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element?.weight_unit
      ?.toString()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element?.volume
      ?.toString()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    VOLUME_UNIT_ENUM_VALUES.get(element?.volume_unit)
      .label.toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim())
  );
}

export function searchVehicle(element: IVehicle, searchValue: string) {
  return (
    searchValue === '' ||
    element?.radio_call_name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element.type_of as IVehicleType)?.abbreviation
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    (element.type_of as IVehicleType)?.crew
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element?.max_cargo
      ?.toString()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element.max_weight
      ?.toString()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element.weight_unit
      ?.toString()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    VEHICLE_STATUS_ENUM_VALUES.get(element.status)
      .label.toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    moment
      .utc(element.available_from)
      .local()
      .format('DD.MM.YYYY')
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim())
  );
}

export function searchUser(element: IUser, searchValue: string) {
  return (
    searchValue === '' ||
    element?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    RADIO_CALL_PREFIX_ENUM_VALUES.get(element.radio_call_name_prefix)
      ?.label.toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    RADIO_CALL_PREFIX_ENUM_VALUES.get(element.radio_call_group_prefix)
      ?.label.toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element.radio_call_group
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element?.phone
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim())
  );
}

export function searchPackageUnit(element: IPackageUnit, searchValue: string) {
  return (
    searchValue === '' ||
    element?.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()) ||
    element?.description
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim())
  );
}
