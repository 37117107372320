import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Card, createStyles, Group, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { HelpDialog } from './HelpDialog';

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    display: 'flex',
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  stat: {
    minWidth: 98,
    minHeight: 140,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  day: {
    fontSize: 44,
    fontWeight: 700,
    lineHeight: 1,
    textAlign: 'center',
    marginBottom: 5,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  month: {
    fontSize: theme.fontSizes.sm,
    lineHeight: 1,
    textAlign: 'center',
  },

  controls: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing.xl * 2,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: 0,
      marginBottom: theme.spacing.xl,
    },
  },

  date: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  control: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'calc(var(--mantine-spacing-xl) * 2)',
    height: 28,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.radius.md,
    transition: 'background-color 50ms ease',

    [theme.fn.smallerThan('xs')]: {
      height: 34,
      width: 34,
    },

    '&:hover': {
      backgroundColor: theme.colors[theme.primaryColor][5],
      color: theme.white,
    },
  },

  controlIcon: {
    [theme.fn.smallerThan('xs')]: {
      transform: 'rotate(-90deg)',
    },
  },
  icon: {
    justifyContent: 'center',
    marginTop: 'var(--mantine-spacing-lg)',
    color: theme.colors.gray[7],
  },
  label: {
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 2,
    color: theme.colors.gray[7],
  },
  value: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    fontSize: 22,
    lineHeight: 1,
    color: theme.colors.gray[7],
  },
}));

function StatsControls(props: {
  data: {
    icon: any;
    label: any;
    value: any;
    description: any;
    EditingDescription: any;
  }[];
}) {
  const { t } = useTranslation();

  const { classes } = useStyles();
  const [date, setDate] = useState(new Date());

  const stats = props.data.map((stat) => (
    <Card
      className={classes.stat}
      radius="md"
      shadow="md"
      p="xs"
      key={stat.label}
    >
      <stat.icon style={{ width: 45, height: 45 }} className={classes.icon} />
      <div>
        <Text className={classes.label}>{stat.label}</Text>
        <Text fz="xs">
          <span className={classes.value}>{stat.value}</span>
        </Text>
        <HelpDialog
          description={stat.description}
          EditingDescription={stat.EditingDescription}
        />
      </div>
    </Card>
  ));

  return (
    <Card withBorder className={classes.root} sx={{ height: '100%' }}>
      <div className={classes.controls}>
        <div className={classes.date}>
          <Text className={classes.day}>{dayjs(date).format('DD')}</Text>
          <Text className={classes.month}>{dayjs(date).format('MMMM')}</Text>
        </div>
      </div>
      <Group style={{ flex: 1 }}>{stats}</Group>
    </Card>
  );
}

export default StatsControls;
