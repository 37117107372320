import React, { useContext } from 'react';
import { createStyles, Grid } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import StatsVerticalBarChart from './StatsVerticalBarChart';
import {
  ResourceContext,
  StatisticContext,
} from '../../context/ContextWrapper';
import { AuthContext, AuthContextType } from '../../context/AuthProvider';
import { AbstractContextType } from '../../context/AbstractProvider';
import IResource from '../../models/IResource';
import { IResourceSection } from '../../models/IStatistic';

interface ChartProps {
  queryResult: IResourceSection[];
}

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    display: 'flex',
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  stat: {
    minWidth: 98,
    minHeight: 140,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  selectorText: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 1,
    textAlign: 'center',
    marginBottom: 5,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  month: {
    fontSize: theme.fontSizes.sm,
    lineHeight: 1,
    textAlign: 'center',
  },

  controls: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing.xl * 2,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: 0,
      marginBottom: theme.spacing.xl,
    },
  },

  date: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  control: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'calc(var(--mantine-spacing-xl) * 2)',
    height: 28,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.radius.md,
    transition: 'background-color 50ms ease',

    [theme.fn.smallerThan('xs')]: {
      height: 34,
      width: 34,
    },

    '&:hover': {
      backgroundColor: theme.colors[theme.primaryColor][5],
      color: theme.white,
    },
  },

  controlIcon: {
    [theme.fn.smallerThan('xs')]: {
      transform: 'rotate(-90deg)',
    },
  },

  icon: {
    justifyContent: 'center',
    marginTop: 'var(--mantine-spacing-lg)',
    color: theme.colors.blue[6],
  },

  label: {
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 2,
  },

  value: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    fontSize: 22,
    lineHeight: 1,
  },
}));

function StatsRequestedResources() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const ctxStatistic = useContext(StatisticContext);
  const resourceCtx = useContext(
    ResourceContext,
  ) as AbstractContextType<IResource>;

  const { getOptions, setOptions } = useContext(AuthContext) as AuthContextType;
  const options = getOptions();

  const transformQueryResult = (queryResult: IResourceSection[]) => {
    const sections: string[] = [];
    const resourcesSet = new Set<string>();
    const sectionResourceMap: Record<string, Record<string, number>> = {};

    queryResult?.forEach((row) => {
      const sectionName = row.section_info?.name || 'Unknown Section';
      const resourceName = row.resource_info?.name || 'Unknown Resource';

      if (!sections.includes(sectionName)) sections.push(sectionName);
      resourcesSet.add(resourceName);

      if (!sectionResourceMap[sectionName]) {
        sectionResourceMap[sectionName] = {};
      }

      sectionResourceMap[sectionName][resourceName] = row.count || 0;
    });

    const resources = Array.from(resourcesSet);

    const datasetsResource = resources.map((resource, index) => ({
      label: resource,
      data: sections.map(
        (section) => sectionResourceMap[section]?.[resource] || 0,
      ),
      backgroundColor: `rgba(${(index * 50) % 255}, ${(index * 100) % 255}, ${(index * 150) % 255}, 0.5)`,
    }));

    const datasetsSection = sections.map((section, index) => ({
      label: section,
      data: resources.map(
        (resource) => sectionResourceMap[section]?.[resource] || 0,
      ),
      backgroundColor: `rgba(${(index * 50) % 255}, ${(index * 100) % 255}, ${(index * 150) % 255}, 0.5)`,
    }));

    return { sections, resources, datasetsResource, datasetsSection };
  };

  const resourceSectionData = transformQueryResult(
    ctxStatistic.entities[0]?.resource_section,
  );

  /*
   * X-Axis: Resources
   * Y-Axis: Sections
   * The plot is the stacked Bar chart
   */
  const dataResourcePerSection = {
    labels: resourceSectionData.sections,
    datasets: resourceSectionData.datasetsResource,
  };

  return (
    <Grid style={{ paddingBottom: '1rem' }}>
      <Grid.Col xs={12}>
        <StatsVerticalBarChart
          decription={t('StatisticsHelp.ResourcePerSection')}
          EditingDescription={t('StatisticsHelp.ResourcePerSectionEditing')}
          title={
            t('StatsControlsBarchart.RequestedResources') +
            ' (' +
            (options.indicator_timespan == 1
              ? t('StatsControlsBarchart.LastHour')
              : t('StatsControlsBarchart.TimeInterval', {
                  name: options.indicator_timespan.toString(),
                })) +
            ')'
          }
          data={dataResourcePerSection}
          stacked={true}
        />
      </Grid.Col>
    </Grid>
  );
}

export default StatsRequestedResources;
