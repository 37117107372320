import { ActionIcon, Modal } from '@mantine/core';
import { useState } from 'react';
import { InfoCircle } from 'tabler-icons-react';
import { useTranslation } from 'react-i18next';
import { Help } from './Help';

export function HelpDialog(props: {
  description: string;
  EditingDescription: string;
}) {
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('StatisticsHelp.Title')}
        size="lg"
      >
        <Help
          description={props.description}
          EditingDescription={props.EditingDescription}
          onFinish={() => setOpened(false)}
        />
      </Modal>
      <ActionIcon
        onClick={() => setOpened(true)}
        color="yellow"
        size="lg"
        title={t('StatisticsHelp.Title')}
      >
        <InfoCircle />
      </ActionIcon>
    </>
  );
}
