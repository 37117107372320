import IInquiry, { getPrefixedInquiryId } from '../../models/IInquiry';
import { ActionIcon, Modal } from '@mantine/core';
import { useState } from 'react';
import { InfoCircle } from 'tabler-icons-react';
import { useTranslation } from 'react-i18next';
import { InquiryInfo } from './InquiryInfo';

interface Props {
  inquiry: IInquiry;
}

export function InquiryInfoDialog(props: Props) {
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('InquiryInfoDialog.title', {
          id: getPrefixedInquiryId(props.inquiry.id),
        })}
        size="lg"
      >
        <InquiryInfo
          inquiry={props.inquiry}
          onFinish={() => setOpened(false)}
        />
      </Modal>
      <ActionIcon
        onClick={() => setOpened(true)}
        color={props.inquiry.comment ? 'blue' : 'yellow'}
        size="lg"
        title={t('InquiryInfoDialog.detail', {
          id: getPrefixedInquiryId(props.inquiry.id),
        })}
      >
        <InfoCircle />
      </ActionIcon>
    </>
  );
}
